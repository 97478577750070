import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { TextFieldProps } from "@mui/material/TextField";
import {
  AlertCancelIconButton,
  AlertInput,
  AlertUpdateIconButton
} from "./alert-details-components";

type IAlertTextField = TextFieldProps & {
  onSubmit: (value: string) => void;
  value: string;
};
const AlertTextField = ({
  value,
  disabled,
  variant,
  onSubmit,
  name,
  ...props
}: IAlertTextField) => {
  const { t } = useTranslation();
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleChange = e => {
    setInputValue(e.target.value);
  };

  const handleFocus = () => setShowButtons(true);
  const handleSubmitClick = () => {
    setShowButtons(false);
    onSubmit(inputValue);
  };
  const handleCancelClick = () => {
    setShowButtons(false);
    setInputValue(value);
  };

  return (
    <Box position="relative" width={"100%"}>
      <AlertInput
        variant={variant || "standard"}
        value={inputValue}
        disabled={disabled}
        onFocus={handleFocus}
        onChange={handleChange}
        name={name}
        inputProps={{
          "data-testid": `alertInput-${name}`
        }}
        {...props}
      />
      {showButtons && (
        <Box
          position={"absolute"}
          zIndex={1}
          right={0}
          display={"flex"}
          gap={1}
          bottom={"-44px"}
        >
          <AlertUpdateIconButton
            title={t("update")}
            aria-label={t("update")}
            data-testid={`alertInput-${name}-submit`}
            onClick={handleSubmitClick}
          >
            <CheckIcon />
          </AlertUpdateIconButton>
          <AlertCancelIconButton
            title={t("cancel")}
            aria-label={t("cancel")}
            data-testid={`alertInput-${name}-cancel`}
            onClick={handleCancelClick}
          >
            <ClearIcon />
          </AlertCancelIconButton>
        </Box>
      )}
    </Box>
  );
};

export default AlertTextField;
