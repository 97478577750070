import React from "react";
import { createTheme } from "@mui/material/styles";
import { LinkProps } from "@mui/material/Link";
import { createStyled } from "@mui/system";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import { Palette } from "@mui/material";

export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return React.createElement(RouterLink, { ref, to: href, ...other });
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    custom: Palette["primary"] & {
      contrastTableText: string;
      highlandsTwilight: string;
      alert: {
        bluishPurple: string;
        coldSteel: string;
        skyBlue: string;
        guillimanBlue: string;
        red: string;
      };
    };
    secondary: Palette["secondary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    custom?: PaletteOptions["primary"] & { contrastTableText: string };
    secondary?: PaletteOptions["secondary"];
  }
}

const palette = {
  neutral: {
    main: "red",
    contrastText: "#fff"
  },
  custom: {
    main: "red",
    contrastText: "#fff",
    contrastTableText: "#263238",
    dark: "#3CC13B",
    highlandsTwilight: "#484686",
    alert: {
      bluishPurple: "#6361B8",
      coldSteel: "#282535",
      skyBlue: "#7ED8FF",
      guillimanBlue: "#7092EF",
      red: "#CE5D5D"
    }
  },
  primary: {
    main: "#225B7B",
    light: "#bababa",
    dark: "#122945",
    playerColor: "#d9d9d9",
    grey: "#e0e0e0"
  },
  secondary: {
    main: "#7dcdd1",
    dark: "#61c69e",
    grey: "#f0f0f0"
  },
  error: {
    main: "#d82f44"
  }
};

const defaultTritonTheme = createTheme({
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),

    h1: {
      "&.color-white": {
        color: "#fff"
      }
    },
    h2: {
      "&.color-white": {
        color: "#fff"
      }
    },
    h3: {
      "&.color-white": {
        color: "#fff"
      }
    },
    h4: {
      "&.color-white": {
        color: "#fff"
      }
    },
    h5: {
      "&.color-white": {
        color: "#fff"
      }
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      "&.color-white": {
        color: "#fff"
      }
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#263238",
      "&.color-white": {
        color: "#fff"
      }
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "#263238",
      "&.color-white": {
        color: "#fff"
      }
    },
    subtitle1: {
      "&.color-white": {
        color: "#fff"
      }
    },
    subtitle2: {
      "&.color-white": {
        color: "#fff"
      }
    }
  },
  palette,
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.booking": {
            border: "0.5 px solid #808080",
            borderRadius: "5px",
            ".MuiSelect-select": {
              color: "#808080"
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
              color: "#808080"
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.filters": {
            border: "-0.9px solid #808080",
            borderRadius: "5px",
            ".MuiSelect-select": {
              color: "#808080"
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff",
              color: "#808080"
            }
          },
          "&.multiSelectDropdown": {
            marginBottom: "20px",
            "& .MuiInputLabel-root": {
              backgroundColor: "#fff"
            }
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ".MuiDataGrid-main": {
            border: "none"
          },
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: "#F0F0F0",
            borderRadius: 0
          },
          ".MuiDataGrid-cell": {
            borderLeft: "1px solid rgba(0,0,0,.1)",
            "&:last-child": {
              borderLeft: "0",
              borderRight: "1px solid rgba(0,0,0,.1)"
            }
          },

          ".MuiDataGrid-columnHeader": {
            borderLeft: "1px solid rgba(0,0,0,.1)"
          },

          ".MuiInputBase-inputTypeSearch": {
            width: 350,
            minWidth: "20%",
            maxWidth: 350
          }
        }
      }
    },
    MuiDataGridMenu: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            backgroundColor: "#ff0"
          }
        }
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            borderRadius: 0,
            backgroundColor: "#fff",
            boxShadow: "0px 14px 48px rgba(0,0,0,0.12)"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          height: "auto",
          maxWidth: "600px",
          lineHeight: "18px",
          marginTop: "2px"
        }
      }
    },
    ListItemTextCustom: {
      styleOverrides: {
        root: {
          borderColor: palette.primary.grey
        }
      }
    },
    ComboNumber: {
      styleOverrides: {
        root: {
          borderColor: palette.primary.grey,
          backgroundColor: palette.secondary.grey
        }
      }
    },
    MatrixHead: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.grey,
          "& .MuiTableCell-root": {
            borderColor: palette.primary.grey
          }
        }
      }
    },
    MoneyMatrix: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            borderColor: palette.primary.grey
          },
          "&:first-child": {
            borderColor: palette.primary.grey
          }
        }
      }
    }
  }
});

export { defaultTritonTheme };

const styled = createStyled({ defaultTheme: defaultTritonTheme });
export default styled;
