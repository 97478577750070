import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { prism } from "@tsg/1st-grpc-web";
import AlertSubjectType from "../alert-subject-type/alert-subject-type";
import AlertActionsButton from "../alert-actions-button/alert-actions-button";
import { stronachgroup } from "@tsg/1st-grpc-js/lib";
import AlertEnums = stronachgroup.first.prism.v1.core.AlertEnums;
import {
  AlertAcknowledgedButton,
  AlertSendEmailButton,
  AlertUnacknowledgedButton
} from "./alert-details-components";

interface AlertDetailsHeaderProps {
  activeAlert: prism.v1.core.IAlert;
  onChangeStatus: () => void;
}

const AlertDetailsHeader = ({
  activeAlert,
  onChangeStatus
}: AlertDetailsHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} mb={4} alignItems={"center"} display={"flex"}>
      <Grid item xs={4}>
        <AlertSubjectType
          subjectType={activeAlert?.subjectType}
          parent={activeAlert?.parent}
        />
      </Grid>
      <Grid item xs={8} display={"flex"} gap={2} justifyContent={"flex-end"}>
        {activeAlert?.state === AlertEnums.State.STATE_UNACKNOWLEDGED ? (
          <AlertAcknowledgedButton
            disabled={!activeAlert}
            onClick={onChangeStatus}
            data-testid={"alert-details-status-button"}
          >
            {t("alerts-center.STATE_ACKNOWLEDGED")}
          </AlertAcknowledgedButton>
        ) : (
          <AlertUnacknowledgedButton
            disabled={!activeAlert}
            onClick={onChangeStatus}
            data-testid={"alert-details-status-button"}
          >
            {t("alerts-center.STATE_UNACKNOWLEDGED")}
          </AlertUnacknowledgedButton>
        )}
        <AlertSendEmailButton
          disabled
          startIcon={<ForwardToInboxIcon />}
          data-testid={"alert-details-email-button"}
        >
          {t("alerts-center.email")}
        </AlertSendEmailButton>
        <AlertActionsButton
          alertName={activeAlert?.name || ""}
          alertActions={activeAlert?.actions || []}
          disabled={!activeAlert}
        />
      </Grid>
    </Grid>
  );
};

export default AlertDetailsHeader;
