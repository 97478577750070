import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import { prism } from "@tsg/1st-grpc-web";
import { LIST_CONNECTIONS } from "common/QueryKeys";
import ConnectionForm from "components/itsp/connection-form";
import { useSnackbar } from "components/snackbar-context/snackbar-context";
import { useItspApi } from "hooks/api/itsp/useItspApi";
import { useTranslation } from "react-i18next";

interface IProps {
  connection: prism.v1.itsp.IItspConnection;
  onClose: () => void;
  open: boolean;
}

const ConnectionDetailsModal = (props: IProps) => {
  const { connection, onClose, open } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showSuccessSnack } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    useDeleteItspConnection,
    useArchiveItspConnection,
    useEnableItspConnection,
    useDisableItspConnection
  } = useItspApi();

  const { mutateAsync: deleteItspConnection, isPending: isDeleting } =
    useDeleteItspConnection();

  const { mutateAsync: archiveItspConnection, isPending: isArchiving } =
    useArchiveItspConnection();

  const { mutateAsync: enableItspConnection, isPending: isEnabling } =
    useEnableItspConnection();

  const { mutateAsync: disableItspConnection, isPending: isDisabling } =
    useDisableItspConnection();

  const isLoading = [isDeleting, isArchiving, isEnabling, isDisabling].some(
    Boolean
  );

  const handleClose = () => {
    if (isUpdating) {
      setIsUpdating(false);
    }
    onClose();
  };

  const handleDelete = () => {
    deleteItspConnection({
      name: connection.name
    })
      .then(() => {
        showSuccessSnack(
          t("connections_commands_success_message.DeleteItspConnection")
        );
        setIsUpdating(false);
        return queryClient.invalidateQueries({
          queryKey: [LIST_CONNECTIONS]
        });
      })
      .then(onClose);
  };

  const handleArchive = () => {
    archiveItspConnection({
      name: connection.name
    }).then(() => {
      showSuccessSnack(
        t("connections_commands_success_message.ArchiveItspConnection")
      );
      setIsUpdating(false);
      return queryClient.invalidateQueries({
        queryKey: [LIST_CONNECTIONS]
      });
    });
  };

  const handleEnable = () => {
    enableItspConnection({
      name: connection.name
    }).then(() => {
      showSuccessSnack(
        t("connections_commands_success_message.EnableItspConnection")
      );
      setIsUpdating(false);
      return queryClient.invalidateQueries({
        queryKey: [LIST_CONNECTIONS]
      });
    });
  };

  const handleDisable = () => {
    disableItspConnection({
      name: connection.name
    }).then(() => {
      showSuccessSnack(
        t("connections_commands_success_message.DisableItspConnection")
      );
      setIsUpdating(false);
      return queryClient.invalidateQueries({
        queryKey: [LIST_CONNECTIONS]
      });
    });
  };

  return (
    connection && (
      <Dialog open={open} onClose={handleClose} fullWidth>
        {isLoading || isUpdating ? (
          <LinearProgress />
        ) : (
          <Box sx={{ height: "4px", width: "100%" }} />
        )}
        <DialogContent>
          <ConnectionForm
            isLoading={isLoading}
            connection={connection}
            onCancel={handleClose}
            onCreateUpdate={() => setIsUpdating(true)}
            onError={() => setIsUpdating(false)}
            onSave={() => setIsUpdating(false)}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button disabled={true} onClick={handleDelete} color={"error"}>
            Delete
          </Button> */}
          {connection.connectionState !==
            prism.v1.itsp.ItspConnectionEnums.ConnectionState
              ?.CONNECTION_STATE_ARCHIVED && (
            <>
              {connection.connectionState ===
                prism.v1.itsp.ItspConnectionEnums.ConnectionState
                  ?.CONNECTION_STATE_INACTIVE && (
                <Button
                  onClick={handleEnable}
                  disabled={isLoading}
                  color="success"
                  data-testid={"itsp-connection-details-enable-button"}
                >
                  {t("connection_action_button.enable")}
                </Button>
              )}
              {connection.connectionState ===
                prism.v1.itsp.ItspConnectionEnums.ConnectionState
                  ?.CONNECTION_STATE_ACTIVE && (
                <Button
                  onClick={handleDisable}
                  disabled={isLoading}
                  color="error"
                  data-testid={"itsp-connection-details-disable-button"}
                >
                  {t("connection_action_button.disable")}
                </Button>
              )}
            </>
          )}
          {connection.connectionState !==
            prism.v1.itsp.ItspConnectionEnums.ConnectionState
              ?.CONNECTION_STATE_ARCHIVED && (
            <Button
              onClick={handleArchive}
              disabled={isLoading}
              data-testid={"itsp-connection-details-archive-button"}
            >
              {t("connection_action_button.archive")}
            </Button>
          )}
          <Button
            onClick={handleClose}
            disabled={isLoading}
            data-testid={"itsp-connection-details-cancel-button"}
          >
            {t("connection_action_button.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default ConnectionDetailsModal;
