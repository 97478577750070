import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import {
  MARKETS_KEY,
  MARKET_SOURCES_KEY,
  WAGERING_SOURCES_KEY,
  DATA_MARKET_TYPE_KEY,
  MARKET_TOTALS_KEY,
  MARKET_GROSS_TOTALS_KEY
} from "common/QueryKeys";
import { GridComponent } from "components/common/Grid";
import {
  APP_MARGIN_TOP,
  APP_PADDING_VERTICAL
} from "components/style/app-style";
import MarketPoolsFilter from "components/transaction/MarketPool/market-pools-filter";
import { FilterValues } from "components/transaction/MarketPool/market-pools-filter/market-pools-filter";
import { useDataApi } from "hooks/api/data/DataAPI";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import Columns from "./column";
import { DataGridProProps, GridValueGetterParams } from "@mui/x-data-grid-pro";
import DetailPanelContent from "components/transaction/MarketPool/detail-panel";
import { prism } from "@tsg/1st-grpc-web";
import CustomCell from "components/common/Grid/custom-cell";
import { stronachgroup } from "@tsg/1st-grpc-js/lib";
import IMarketTotal = stronachgroup.first.prism.v1.transaction.IMarketTotal;

type MarketTotalItem = prism.v1.transaction.IMarketTotal & {
  keepAsCombinationPool: number;
  marketNested: prism.v1.data.IMarket;
  marketSourceNested: prism.v1.data.IMarketSource;
  wageringSourceTotals: Record<string, any>[];
  marketGrossTotals: prism.v1.transaction.MarketTotal;
  combosWagerSource?: Record<
    string,
    prism.v1.transaction.MarketTotal.IWageringSourceTotal
  >;
  path?: string[];
  selectedWageringSource?: Record<string, string>;
};

export default function MarketPoolsPage() {
  const { t } = useTranslation();

  const [filterValues, setFilterValues] = useState<FilterValues>();
  const [selectedWageringSource, setSelectedWageringSource] = useState<
    Record<string, string>
  >({});

  const {
    useListWageringSources,
    useListMarketTotals,
    useGetMarketGrossTotals
  } = useTransactionApi();

  const { useListMarkets, useListMarketSources, useListMarketTypes } =
    useDataApi();

  const {
    data: marketTypesData,
    isLoading: isMarketTypesLoading,
    refetch: refetchMarketTypes,
    isRefetching: isMarketTypesRefetching
  } = useListMarketTypes(DATA_MARKET_TYPE_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const {
    data: marketTotalsData,
    isLoading: isMarketTotalsLoading,
    refetch: refetchMarketTotals,
    isRefetching: isMarketTotalsRefetching
  } = useListMarketTotals(MARKET_TOTALS_KEY, {
    filter: [
      {
        market: filterValues?.markets
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  console.log("marketTotalsData", marketTotalsData);

  const marketTotalsArr = marketTotalsData?.market_Totals || [];

  const marketTypes = marketTypesData?.marketTypes || [];

  const keepAsCombinationPoolMap = useMemo(() => {
    return marketTypes.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.name]: cur.keepAsCombinationPool
      };
    }, {});
  }, [marketTypes]);

  const {
    data: marketSourcesData,
    isLoading: isMarketSourcesLoading,
    refetch: refetchMarketSources,
    isRefetching: isMarketSourcesRefetching
  } = useListMarketSources(MARKET_SOURCES_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    filter: [
      {
        state: Object.keys(prism.v1.data.MarketEnums.State)
          .map(key => {
            if (
              [
                prism.v1.data.MarketEnums.State.STATE_INCOMPLETE,
                prism.v1.data.MarketEnums.State.STATE_CANCELLED
              ].every(type => prism.v1.data.MarketEnums.State[key] !== type)
            ) {
              return prism.v1.data.MarketEnums.State[key];
            }

            return null;
          })
          .filter(it => it !== null)
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const markets = useMemo(
    () =>
      (marketsData?.markets || []).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name]: cur
        }),
        {}
      ),
    [marketsData]
  );
  const marketTotals = useMemo(
    () =>
      marketTotalsArr?.filter(marketTotal => {
        const market = markets[marketTotal.market];

        if (!market) {
          return false;
        }

        return true;
      }) || [],
    [marketTotalsArr, markets]
  );

  const {
    data: wageringSourcesData,
    isLoading: isWageringSourcesLoading,
    refetch: refetchWageringSources,
    isRefetching: isWageringSourcesRefetching
  } = useListWageringSources(WAGERING_SOURCES_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const wageringSources = wageringSourcesData?.wageringSources || [];
  console.log("wageringSources", wageringSources);

  const wageringSourcesMap = wageringSources.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.name]: cur
    }),
    {}
  );
  console.log("wageringSourcesMap", wageringSourcesMap);

  const { data: marketGrossTotalsData } = useGetMarketGrossTotals(
    MARKET_GROSS_TOTALS_KEY,
    {
      markets: marketTotals?.map(mt => mt.market),
      wageringSources: wageringSources?.map(ws => ws.name)
    },
    {
      enabled: marketTotals?.length > 0 && wageringSources?.length > 0
    }
  );
  console.log("marketGrossTotalsData", marketGrossTotalsData);

  const marketGrossTotalsMap = useMemo(
    () =>
      (marketGrossTotalsData?.grossTotals || []).reduce((acc, cur) => {
        return {
          ...acc,
          [cur.market]: cur
        };
      }, {} as Record<string, prism.v1.transaction.IMarketGrossTotalsResponse>),
    [marketGrossTotalsData]
  );

  const marketSourcesMap = useMemo(
    () =>
      (marketSourcesData?.marketSources || []).reduce((acc, cur) => {
        return {
          ...acc,
          [cur.name]: cur
        };
      }, {}),
    [marketSourcesData]
  );
  const marketPools: MarketTotalItem[] =
    marketTotals.map(({ wageringSourceTotals, ...mp }: IMarketTotal) => {
      return {
        ...mp,
        ...{
          marketGrossTotals: marketGrossTotalsMap?.[mp.market] || {},
          wageringSourceTotals:
            wageringSourceTotals?.map(
              wst =>
                ({
                  ...wst,
                  wageringSourceNested:
                    wageringSourcesMap[wst.wageringSourceName]
                } || [])
            ) || [],
          wageringSourceNames:
            wageringSourceTotals?.map(
              wst =>
                wageringSourcesMap[wst.wageringSourceName].shortName ||
                wst.wageringSourceName
            ) || []
        },
        marketNested: markets?.[mp.market] || {},
        marketSourceNested: marketSourcesMap[mp.source],
        keepAsCombinationPool:
          keepAsCombinationPoolMap?.[markets?.[mp.market]?.marketTypeName]
      };
    }) || [];

  console.log("marketPools", marketPools);
  const isRefetching = [
    isMarketSourcesRefetching,
    isMarketsRefetching,
    isWageringSourcesRefetching,
    isMarketTypesRefetching,
    isMarketTotalsRefetching
  ].some(Boolean);

  const isLoading = [
    isMarketSourcesLoading,
    isMarketsLoading,
    isWageringSourcesLoading,
    isMarketTypesLoading,
    isMarketTotalsLoading,
    isRefetching
  ].some(Boolean);

  const getDetailPanelContent = useCallback<
    DataGridProProps["getDetailPanelContent"]
  >(
    ({ row }) => (
      <DetailPanelContent
        title="Gross Sales By Wagering Source"
        matrixData={row.combosWagerSource}
        selectedWageringSourceName={row.selectedWageringSource[row.name] || ""}
      />
    ),
    [selectedWageringSource]
  );

  const getDetailPanelHeight = () => "auto";

  const refetch = () => {
    refetchMarketSources();
    refetchMarkets();
    refetchWageringSources();
    refetchMarketTypes();
    refetchMarketTotals();
  };

  const handleChangeSelectedWageringSource = (
    wageringSourceName: string,
    name: number | string,
    state?: Record<string, string>
  ) => {
    setSelectedWageringSource({
      ...Object.entries(state || selectedWageringSource).reduce(
        (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
        {}
      ),
      [name]: wageringSourceName
    });
  };

  const handleFilterChange = values => setFilterValues(values);

  const handleGenerateData = (marketPoolsArr: MarketTotalItem[]) => {
    if (
      isMarketTotalsLoading ||
      isMarketSourcesLoading ||
      isMarketsLoading ||
      isWageringSourcesLoading
    )
      return [];

    const treeData = [];

    const sortedMarketPools: MarketTotalItem[] = marketPoolsArr.map(mp => {
      const combosWagerSource: Record<
        string,
        prism.v1.transaction.MarketTotal.IWageringSourceTotal
      > = {};
      const sortedPoolCombos = [];

      mp?.wageringSourceTotals?.forEach(item => {
        combosWagerSource[item.wageringSourceName] = item;
      });

      return {
        ...mp,
        combosWagerSource,
        sortedPoolCombos
      };
    });

    const paths = sortedMarketPools.map((mp, idx) => {
      const parentPath = `Local-Total-${idx}`;

      let pools = [];

      pools =
        Object.values(mp.combosWagerSource)
          .flat()
          ?.map((_, idx) => `Combo-${idx}`) || [];

      return [parentPath, ...pools.filter(Boolean)];
    });

    sortedMarketPools.forEach((mp, idx) => {
      const parentPath = paths[idx][0];
      paths[idx].forEach((path, idx) => {
        treeData.push({
          ...mp,
          path: idx === 0 ? [parentPath] : [parentPath, path]
        });
      });
    });

    return treeData.map((td, idx) => ({
      ...td,
      selectedWageringSource,
      id: idx
    }));
  };

  const getTreeDataPath = row => {
    return row.path;
  };

  const groupingColDef = {
    field: "marketSourceDisplayName",
    headerName: t("market_pool_market_source_display_name"),
    hide: false,
    editable: false,
    sortable: true,
    minWidth: 250,
    width: 250,
    renderCell: (params: GridValueGetterParams) => {
      return <CustomCell sx={{ height: "100%" }} params={params} />;
    },
    valueGetter: (params: GridValueGetterParams) => {
      return (
        params.row.marketSourceNested?.displayName?.value || params.row.source
      );
    }
  };

  const treeData = handleGenerateData(marketPools);
  console.log("treeData", treeData);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: {
          xs: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.xs * 2
          }px)`,
          md: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.md * 2
          }px)`,
          lg: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.lg * 2
          }px)`
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell": {
          alignItems: "flex-start",
          "& .MuiListItemText-root:first-child": {
            marginTop: "0"
          }
        }
      }}
    >
      <MarketPoolsFilter onFilterChange={handleFilterChange} />
      <GridComponent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column"
        }}
        idProp={"id"}
        getRowId={row => row.name}
        rowData={treeData}
        columnDefs={Columns(t, handleChangeSelectedWageringSource)}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        pageTitle={t("market_totals")}
        initialState={{
          sorting: {
            sortModel: [{ field: "marketDisplayName", sort: "asc" }]
          },
          rowGrouping: {
            model: ["marketSourceDisplayName"]
          }
        }}
        rowHeight={"auto"}
        disableAddButton={true}
        loading={isLoading}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
        treeData={true}
        getTreeDataPath={getTreeDataPath}
        disableCellMouseEvents={true}
        groupingColDef={groupingColDef}
      >
        <></>
      </GridComponent>
    </Box>
  );
}
