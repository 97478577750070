import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowsProp,
  gridClasses,
  GridProSlotsComponent,
  GridLoadingOverlay
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { closeIconStyle, modalStyle } from "components/style";
import _isEqual from "lodash/isEqual";
import React, { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../Dialog";
import { SEVERITY, ToastContext } from "../Dialog/alert/toast";
import { usePrismWebContext } from "../context";
import { CustomToolbar } from "./customToolbar";
import { ExpandableCell } from "./expandableCell";
import useWindowHeight from "./use-window-height";
import { GridActions } from "../../../common/utils/enum-utils";
import LinearProgress from "@mui/material/LinearProgress";
interface Props {
  rowData?: GridRowsProp[];
  columnDefs?: GridColDef[];
  idProp?: string;
  children?: ReactElement;
  pageTitle?: string;
  checkboxLabel?: string;
  tooltipTitle?: string;
  editType?: string;
  initialState?;
  loading?: boolean;
  disableAddButton?: boolean;
  activeStateFilter?: (x: boolean) => void;
  handleAction?: (x: string, y: string) => void;
  actionMethod?;
  modalSize?: "sm" | "md" | "lg" | "xl" | "xs";
  onCellClick?;
  rowHeight?: string | number;
  gridMessage?: string;
  showEdit?: boolean;
  onSave?: (x: any[]) => void;
  onCancel?: () => void;
  gridRef?: React.MutableRefObject<GridApiPro>;
  newRow?: (x: any[]) => void;
  setShowActionButtons?: (x: boolean) => void;
  showActionButtons?: boolean;
  handleCellEdit?: (params: GridCellParams<any, any, any>) => boolean;
  setEnableSaveButton?: (x: boolean) => void;
  showAdd?: boolean;
  disableDeleteButton?: boolean;
  deleteTooltipTitle?: string;
  enableSaveButton?: boolean;
  autoHideErrors?: boolean;
  disableExport?: boolean;
  disableEditButton?: boolean;
  disablePrintOption?: boolean;
  customPopperWidth?: number;
  isAuthorizationRuleExist?: boolean;
  isTaxUpdated?: boolean;
  setIsRowUpdated?: (x: boolean) => void;
  showQuickFilter?: boolean;
  setIsMove?: (text: boolean) => void;
  setDomainAction?: (text: string) => void;
  refreshButton?: { onRefresh: () => void; isRefreshingData: boolean };
  displayNameInConfirmation?: boolean;
  treeData?: boolean;
  getTreeDataPath?: (x: any) => any[];
  disableCellMouseEvents?: boolean;
  groupingColDef?: GridColDef;
  getDetailPanelContent?: DataGridProProps["getDetailPanelContent"];
  getDetailPanelHeight?: DataGridProProps["getDetailPanelHeight"];
  showMultiFilter?: boolean;
  customTableAction?: ReactElement;
  testIdPrefix?: string;
}

export function GridComponent({
  rowData,
  columnDefs,
  idProp = "name",
  children,
  pageTitle,
  checkboxLabel = null,
  tooltipTitle,
  editType = "modal",
  initialState = null,
  loading = false,
  disableAddButton = false,
  activeStateFilter = null,
  handleAction,
  actionMethod = null,
  modalSize = "md",
  onCellClick,
  rowHeight = "auto",
  showEdit,
  gridRef,
  newRow,
  setShowActionButtons,
  showActionButtons,
  handleCellEdit,
  setEnableSaveButton,
  disableDeleteButton = false,
  deleteTooltipTitle,
  showAdd,
  enableSaveButton,
  autoHideErrors = true,
  gridMessage,
  disableExport = false,
  disableEditButton = false,
  disablePrintOption = true,
  customPopperWidth,
  isAuthorizationRuleExist,
  isTaxUpdated,
  setIsRowUpdated,
  showQuickFilter = true,
  setIsMove,
  displayNameInConfirmation = false,
  setDomainAction,
  refreshButton,
  treeData = false,
  getTreeDataPath,
  disableCellMouseEvents = false,
  groupingColDef,
  getDetailPanelContent,
  getDetailPanelHeight,
  showMultiFilter = false,
  customTableAction,
  testIdPrefix
}: Props) {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [alert, setAlert] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [openAction, setOpenAction] = React.useState(false);
  const [editRows, setEditRows] = React.useState(rows);
  const [cols, setCols] = useState(
    [...columnDefs]?.map(col => {
      col.minWidth = col.minWidth || 170;
      col.width = col.width || 200;
      if (col.type !== "actions" && !col.renderCell) {
        col.renderCell = params => <ExpandableCell {...params} />;
      }
      return col;
    })
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [confirmation, setConfirmation] = React.useState(false);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [selectedAction, setSelectedAction] = React.useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState(cols);
  const { isRowHoveredState, hoveredRowIdState, hoveredFieldState } =
    usePrismWebContext();
  const [isRowHovered, setIsRowHovered] = isRowHoveredState;
  const [hoveredRowId, setHoveredRowId] = hoveredRowIdState;
  const [hoveredFieldValue, setHoveredFieldValue] = hoveredFieldState;
  const gridHeight = useWindowHeight() - 180;
  const originalRowsRef = React.useRef([]);

  React.useEffect(() => {
    if (rowData && !enableSaveButton) {
      setRows(rowData);
    }
  }, [rowData]);

  React.useEffect(() => {
    originalRowsRef.current = rowData;
  }, [rowData]);

  React.useEffect(() => {
    if (actionMethod) {
      actionMethod.current = handleRowAction;
    }
  });

  const updateLowHighRange = (rows, rowIndex) => {
    if (rows?.length > 0) {
      rows[rows?.length - 1].highRange = 2147483647;
    }
    if (rowIndex > 0 && rowIndex < rows?.length) {
      rows[rowIndex].lowRange = rows[rowIndex - 1].highRange + 1;
    }
  };

  const handleRemoveAction = id => {
    const indexToRemove = rows?.findIndex(row => row[idProp] === id?.row?.id);
    if (indexToRemove !== -1) {
      const updatedRows = rows.filter((_, index) => index !== indexToRemove);
      updateLowHighRange(updatedRows, indexToRemove);
      setRows(updatedRows);
      setEnableSaveButton(true);
    }
  };

  const handleRowAction = (action, id) => {
    if (rows?.map(x => x[idProp] === id)) {
      setSelectedAction(action);
      if (action === GridActions.EDIT) {
        setOpenAction(true);
        const updatedData = rows.filter(x => x[idProp] == id);
        setEditRows(updatedData);
      } else if (action === GridActions.REMOVE) {
        handleRemoveAction(id);
      } else if (action === GridActions.ADD_ROW) {
        handleAddRowClick();
      } else if (action === GridActions.CSV) {
        handleAction(action, id);
      } else {
        setConfirmationMessage(action + "_confirmation");
        setConfirmation(true);
        setSelectedRowId(id);
      }
    }
  };

  const handleConfirmationClose = flag => {
    if (selectedAction && selectedRowId) {
      handleConfirm(flag, selectedRowId);
      setAnchorEl(null);
      setOpenAction(false);
    } else {
      setConfirmation(false);
    }
    setSelectedRowId(null);
  };

  const handleConfirm = (flag, rowId) => {
    if (flag && selectedRowId === rowId) {
      handleAction(selectedAction, rowId);
    }
    setConfirmation(false);
  };

  const handleClose = () => {
    setIsMove && setIsMove(false);
    setDomainAction && setDomainAction(null);
    setOpenAction(false);
  };

  function escapeRegExp(value: string): string {
    return value.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, "\\$&");
  }

  const handleGetRowId = item => {
    return item[idProp];
  };

  const handleColumnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnObj
  ) => {
    const updatedCol = columnDefs.map((x, index) => {
      return { ...x, hide: columnObj[index]?.hide ?? false };
    });
    setCols([...updatedCol]);
  };

  const handleSuccess = message => {
    setOpenAction(false);
    setToast({
      message,
      open: true,
      severity: SEVERITY.SUCCESS
    });
  };

  const handleError = message => {
    setToast({
      message,
      open: true,
      severity: SEVERITY.ERROR,
      autoHideDuration: null
    });
  };

  const handleCellMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (!showActionButtons && !disableCellMouseEvents) {
      const field = event.currentTarget.dataset.field;
      const id = event.currentTarget.parentElement.dataset.id;
      setIsRowHovered(true);
      setHoveredRowId(id);
      setHoveredFieldValue(field);
    }
  };

  const handleCellMouseLeave = () => {
    if (!showActionButtons && !disableCellMouseEvents) {
      setIsRowHovered(false);
      setHoveredRowId("");
      setHoveredFieldValue("");
    }
  };

  React.useEffect(() => {
    if (showActionButtons) {
      setCols(prevCols =>
        prevCols?.map(col => {
          return {
            ...col,
            editable: col.field !== "name" && showActionButtons && true
          };
        })
      );
    }
  }, [showActionButtons, rows]);

  const handleAddRowClick = () => {
    const newRowResult = newRow(rows);
    if (newRowResult !== undefined) {
      setRows(prevRows => [...prevRows, newRowResult]);
    }
  };

  const isCellEditable = (params: GridCellParams<any, any, any>) => {
    return handleCellEdit ? handleCellEdit(params) : false;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const originalValue = originalRowsRef?.current?.find(
      r => r.id === newRow.id
    );
    const isDataChanged = !_isEqual(newRow, originalValue);
    setIsRowUpdated(isDataChanged);
    setEnableSaveButton(isTaxUpdated || isDataChanged);
    setRows(rows.map(row => (row.id === newRow.id ? newRow : row)));
    return newRow;
  };

  const handleDeleteClick = isDelete => {
    isDelete && handleRowAction("delete", rows?.[0]?.name);
  };

  const getTestId = (testId: string) => {
    if (testIdPrefix) {
      return `${testIdPrefix}-${testId}`;
    }
    return testId;
  };

  return (
    <>
      <Box sx={{ height: gridHeight, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={cols}
          pagination
          columnVisibilityModel={columnVisibilityModel}
          initialState={{
            ...initialState,
            pagination: { paginationModel: { pageSize: pageSize } }
          }}
          apiRef={gridRef}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          onPaginationModelChange={newPageSize =>
            setPageSize(newPageSize.pageSize)
          }
          pageSizeOptions={rows.length > 25 ? [25, 50, 100] : []}
          isCellEditable={isCellEditable}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {gridMessage || t("no_records_found")}
              </Stack>
            ),
            noResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t("no_records_found")}
              </Stack>
            ),
            loadingOverlay: refreshButton?.isRefreshingData
              ? (LinearProgress as GridProSlotsComponent["LoadingOverlay"])
              : GridLoadingOverlay
          }}
          slotProps={{
            cell: {
              onMouseEnter: event => handleCellMouseEnter(event),
              onMouseLeave: () => handleCellMouseLeave()
            },
            toolbar: {
              quickFilterProps: { debounceMs: 250 },
              gridColumns: cols as GridColDef[],
              handleColumnChange: handleColumnChange,
              children,
              pageTitle,
              checkboxLabel,
              tooltipTitle,
              disableAddButton,
              activeStateFilter,
              disableExport,
              showEdit,
              setShowActionButtons,
              onDelete: x => handleDeleteClick(x),
              showAdd,
              disableDeleteButton,
              deleteTooltipTitle,
              disableEditButton,
              disablePrintOption,
              customPopperWidth,
              isAuthorizationRuleExist,
              setColumnVisibilityModel,
              showQuickFilter,
              refreshButton,
              showMultiFilter,
              customTableAction,
              testIdPrefix
            },
            filterPanel: {
              sx: {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                borderRadius: "4px"
              }
            },
            baseSelect: {
              native: false
            }
          }}
          getRowId={handleGetRowId}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          isRowSelectable={params => false}
          onCellClick={(params: GridCellParams, event) => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (onCellClick) {
              onCellClick(params, event);
            }
          }}
          loading={loading}
          getRowHeight={() => {
            return typeof rowHeight === "number" ? rowHeight : "auto";
          }}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: pageTitle === "Roles" ? 0 : 1
            },
            [`& .${gridClasses.row}`]: (!rowHeight || rowHeight === "auto") && {
              minHeight: "42px !important"
            }
          }}
          treeData={treeData}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
        />
      </Box>

      {editType === "modal" && (
        <Modal
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          open={openAction}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle(modalSize)} id="modal-modal-description">
            <CloseIcon
              sx={closeIconStyle}
              onClick={handleClose}
              fontSize="small"
              style={{ marginTop: "20px", marginRight: "10px" }}
              data-testid={getTestId("grid-modal-close-button")}
            ></CloseIcon>
            {children &&
              React.cloneElement(children, {
                row: editRows?.[0],
                handleSuccess,
                handleError
              })}
          </Box>
        </Modal>
      )}

      {confirmation && (
        <ConfirmationDialog
          onOpen={confirmation}
          handleClose={handleConfirmationClose}
          text={confirmationMessage}
          targetName={displayNameInConfirmation ? selectedRowId : null}
        />
      )}
    </>
  );
}

export default GridComponent;
