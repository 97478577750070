import { Box } from "@mui/material";
import React from "react";
import { GridColumns } from "@mui/x-data-grid-pro";
import { prism } from "@tsg/1st-grpc-web";
import ConnectionActions from "components/itsp/actions-menu";
import ConnectionStateMark from "components/itsp/connection-state-mark";

export interface ITSPConnection {
  name: string;
  organization: string;
  displayName: string;
  parimutuelPrograms: string;
  eventCode: string;
  localId: string;
  remoteId: string;
  ipAddress: string;
  role: string;
  state: prism.v1.itsp.ItspConnectionEnums.ConnectionState;
  connectionStateStatus: prism.v1.itsp.ItspConnectionStateEnums.ConnectionStatus;
  linkState: prism.v1.itsp.ItspConnectionStateEnums.LinkState;
}

export const Columns = (handleActionClick, t): GridColumns => {
  return [
    {
      field: "name",
      headerName: t("connection_table.name"),
      width: 200,
      hide: true,
      editable: false
    },
    {
      field: "organization",
      headerName: t("connection_table.organization"),
      width: 300
    },
    {
      field: "displayName",
      headerName: t("connection_table.displayName"),
      width: 300,
      editable: true
    },
    {
      field: "parimutuelPrograms",
      headerName: t("connection_table.parimutuelPrograms"),
      width: 300,
      editable: true
    },
    {
      field: "eventCode",
      headerName: t("connection_table.eventCode"),
      width: 200,
      sortable: false
    },

    {
      field: "localId",
      headerName: t("connection_table.localId"),
      width: 300,
      editable: true
    },
    {
      field: "remoteId",
      headerName: t("connection_table.remoteId"),
      width: 300,
      editable: true
    },
    {
      field: "ipAddress",
      headerName: t("connection_table.ipAddress"),
      width: 100,
      editable: false
    },
    {
      field: "role",
      headerName: t("connection_table.role"),
      width: 300,
      editable: true
    },
    {
      field: "state",
      headerName: t("connection_table.state"),
      width: 300,
      editable: true,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "left"
            }}
          >
            <ConnectionStateMark connectionState={params.row.state} />
          </Box>
        );
      },
      valueGetter: params =>
        prism.v1.itsp.ItspConnectionEnums.ConnectionState[params.row.state]
    },
    {
      field: "connectionStateStatus",
      headerName: t("connection_table.connectionStateStatusLabel"),
      width: 300,
      editable: true,
      valueGetter: params =>
        t(
          `connection_table.connectionStateStatus.${
            prism.v1.itsp.ItspConnectionStateEnums.ConnectionStatus[
              params.row.connectionStateStatus
            ]
          }`
        )
    },
    {
      field: "linkState",
      headerName: t("connection_table.linkStateLabel"),
      width: 300,
      editable: true,
      valueGetter: params =>
        t(
          `connection_table.linkState.${
            prism.v1.itsp.ItspConnectionStateEnums.LinkState[
              params.row.linkState
            ]
          }`
        )
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      editable: false,
      renderCell: params => (
        <ConnectionActions
          row={params.row}
          onClickCommand={cmd => handleActionClick(cmd)}
        />
      )
    }
  ];
};
