import { stronachgroup } from "@tsg/1st-grpc-js/lib";
import { format } from "date-fns";
import { TFunction } from "react-i18next";
import IAlert = stronachgroup.first.prism.v1.core.IAlert;
import AlertEnums = stronachgroup.first.prism.v1.core.AlertEnums;

interface AlertInfoProps {
  t: TFunction;
  activeAlert: IAlert;
}
interface AlertInfoResult {
  label: string;
  value: string;
  name?: string;
  editable?: boolean;
  overflow?: boolean;
}
const formatDate = (date?: number): string => {
  return date && format(date, "PPPp");
};
export const getAlertInfo = ({
  t,
  activeAlert
}: AlertInfoProps): AlertInfoResult[][] => {
  return [
    [
      {
        label: t("alerts-center.details.timeLabel"),
        value: activeAlert
          ? formatDate(
              Number(activeAlert?.auditInfo?.createTime?.seconds) * 1000
            )
          : "-"
      },
      {
        label: t("alerts-center.details.statusLabel"),
        value: activeAlert
          ? t(`alerts-center.${AlertEnums.State[activeAlert.state]}`)
          : "-"
      }
    ],
    [
      {
        label: t("alerts-center.details.subjectLabel"),
        value: activeAlert?.subject || "-",
        overflow: true
      },
      {
        label: t("alerts-center.details.lastUpdateBy"),
        value: activeAlert ? activeAlert.auditInfo.updatedBy : "-",
        overflow: true
      }
    ],
    [
      {
        label: t("alerts-center.details.nameLabel"),
        value: activeAlert ? activeAlert.displayName.value : "-",
        name: "displayName",
        editable: true
      },
      {
        label: t("alerts-center.details.lastUpdate"),
        value: activeAlert
          ? formatDate(Number(activeAlert.auditInfo.updateTime.seconds) * 1000)
          : "-"
      }
    ],
    [
      {
        label: t("alerts-center.details.urlLabel"),
        value: activeAlert?.url || "None",
        name: "url",
        editable: true
      }
    ]
  ];
};
