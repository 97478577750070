import { stronachgroup } from "@tsg/1st-grpc-js/lib";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { prism } from "@tsg/1st-grpc-web";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import {
  DATA_PARIMUTUEL_EVENT_KEY,
  LIST_CONNECTIONS,
  LIST_PARIMUTUEL_PROGRAMS,
  LIST_WAGERING_SOURCES
} from "common/QueryKeys";
import { ConnectionPagesStore } from "components/itsp/interfaces/connections-page-state";
import { ITSPConnection } from "pages/itsp/column";
import { enumValueToLabel } from "utils/enum-parser";
import { ItspConnectionFilterState } from "components/itsp/connections-filter-modal/connections-filter-modal";
import { useDataApi } from "../data/DataAPI";
import { useWageringSourceApi } from "../wagering-source/wagering-source-api";
import { useItspApi } from "./useItspApi";
import ListItspConnectionsResponse = stronachgroup.first.prism.v1.itsp.ListItspConnectionsResponse;

interface IITSPConnectionPageData {
  isLoading: boolean;
  filter: ItspConnectionFilterState;
  handleRefetch: () => void;
  parimutuelProgramsMap: prism.v1.data.IParimutuelProgram;
  wageringSourcesMap: prism.v1.transaction.IWageringSource;
  itspConnectionsData: Partial<Omit<ListItspConnectionsResponse, "toJSON">>;
  connectionData: ITSPConnection[];
  isRefetching: boolean;
  isParimutuelProgramsLoading: boolean;
  isWageringSourcesLoading: boolean;
}

export default function useITSPConnectionPageData(): IITSPConnectionPageData {
  const { filter } = useSelector(
    (state: { connectionPages: ConnectionPagesStore }) => {
      return state.connectionPages["all"];
    }
  );
  const { useListParimutuelEvents, useListParimutuelPrograms } = useDataApi();
  const { useListItspConnections } = useItspApi();
  const { useListWageringSources } = useWageringSourceApi();

  const {
    data: listParimutuelEventsData,
    isLoading: isListParimutuelEventsLoading,
    refetch: refetchListParimutuelEvents,
    isRefetching: isListParimutuelEventsRefetching
  } = useListParimutuelEvents(
    DATA_PARIMUTUEL_EVENT_KEY,
    {
      filter: [],
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT
      }
    },
    { refetchInterval: 10000 }
  );

  const {
    isLoading: isListItspConnectionsLoading,
    data: itspConnectionsData,
    refetch: refetchItspConnections,
    isRefetching: isRefetchingItspConnections
  } = useListItspConnections(
    LIST_CONNECTIONS,
    {
      filter: [
        {
          ...filter
        }
      ],
      getOptions: {},
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT,
        includeSummary: true
      }
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: true,
      onError: error => console.error(error)
    }
  );

  const getEventCode = (parimutuelEventName: string) => {
    if (parimutuelEventName && listParimutuelEventsData?.parimutuelEvents) {
      const parimutuelEvent = listParimutuelEventsData.parimutuelEvents.find(
        event => event.name === parimutuelEventName
      );
      if (parimutuelEvent && parimutuelEvent.foreignIds) {
        return parimutuelEvent.foreignIds
          .map(fId => fId.values.join(", "))
          .join(", ");
      }

      return parimutuelEventName;
    }

    return parimutuelEventName;
  };
  const connectionData: ITSPConnection[] = useMemo(() => {
    if (itspConnectionsData?.itspConnections) {
      return itspConnectionsData.itspConnections.reduce(
        (acc: ITSPConnection[], item) => {
          if (item) {
            acc.push({
              name: item.name,
              organization: item.parent,
              displayName: item.displayName.value,
              parimutuelPrograms: item.parimutuelPrograms.join(", "),
              eventCode: getEventCode(item.parimutuelEventName),
              localId: item.localConnectionId,
              remoteId: item.remoteConnectionId,
              ipAddress: `${item.ipAddress}:${item.port}`,
              role: enumValueToLabel(
                item.itspRole,
                prism.v1.itsp.ItspConnectionEnums.ItspRole,
                "ITSPROLE_"
              ),
              state: item.connectionState,
              connectionStateStatus: item.connectionStateStatus,
              linkState: item.linkState
            });
          }
          return acc;
        },
        []
      );
    }
    return [];
  }, [itspConnectionsData, listParimutuelEventsData]);

  const {
    isLoading: isWageringSourcesLoading,
    data: wageringSourcesData,
    refetch: refetchWageringSourcesData,
    isFetching: isFetchingWageringSourcesData
  } = useListWageringSources(
    LIST_WAGERING_SOURCES,
    {
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT,
        includeSummary: true
      }
    },
    {
      onError: error => console.error(error)
    }
  );
  const wageringSourcesMap =
    wageringSourcesData?.wageringSources?.reduce((acc, cur) => {
      return { ...acc, [cur.name]: cur };
    }, {}) || {};

  const {
    isLoading: isParimutuelProgramsLoading,
    data: parimutuelProgramsData,
    refetch: refetchParimutuelProgramsData,
    isFetching: isFetchingParimutuelProgramsData
  } = useListParimutuelPrograms(
    LIST_PARIMUTUEL_PROGRAMS,
    {
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT,
        includeSummary: true
      }
    },
    {
      onError: error => console.error(error)
    }
  );
  const parimutuelProgramsMap =
    parimutuelProgramsData?.parimutuelPrograms?.reduce((acc, cur) => {
      return { ...acc, [cur.name]: cur };
    }, {}) || {};
  const handleRefetch = () => {
    refetchItspConnections();
    refetchListParimutuelEvents();
    refetchWageringSourcesData();
    refetchParimutuelProgramsData();
  };
  const isLoading = [
    isListItspConnectionsLoading,
    isListParimutuelEventsLoading,
    isFetchingWageringSourcesData,
    isFetchingParimutuelProgramsData
  ].some(Boolean);
  const isRefetching = [
    isRefetchingItspConnections,
    isListParimutuelEventsRefetching,
    isFetchingWageringSourcesData,
    isFetchingParimutuelProgramsData
  ].some(Boolean);

  return {
    handleRefetch,
    isLoading,
    filter,
    isRefetching,
    parimutuelProgramsMap,
    connectionData,
    isWageringSourcesLoading,
    wageringSourcesMap,
    isParimutuelProgramsLoading,
    itspConnectionsData
  };
}
