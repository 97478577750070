import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import { prism } from "@tsg/1st-grpc-web";
import AlertEnums = prism.v1.core.AlertEnums;

import { useAlertApi } from "hooks/api/alerts/useAlertApi";
import { SEVERITY, ToastContext } from "../../common/Dialog/alert/toast";
import {
  ALERTS_KEY,
  ALERTS_KEY_ACKNOWLEDGED,
  ALERTS_KEY_UNACKNOWLEDGED
} from "../../../common/QueryKeys";
import { clearActiveAlertId } from "../../../store/actions/alerts";

import { AlertDetailsContainer } from "./alert-details-components";
import AlertDetailsHeader from "./alert-details-header";
import AlertTextField from "./alert-text-field";
import { getAlertInfo } from "./get-alert-info";

interface OwnProps {
  activeAlert: prism.v1.core.IAlert;
}

const AlertDetails = ({ activeAlert }: OwnProps) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const upsertCallback = (successMessage, clearActiveAlert = false) => {
    return {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [ALERTS_KEY]
        });
        queryClient.invalidateQueries({
          queryKey: [ALERTS_KEY_UNACKNOWLEDGED]
        });
        queryClient.invalidateQueries({
          queryKey: [ALERTS_KEY_ACKNOWLEDGED]
        });
        clearActiveAlert && dispatch(clearActiveAlertId());
        setToast({
          message: t(successMessage),
          open: true,
          severity: SEVERITY.SUCCESS
        });
      },
      onError: errorResponse => {
        setToast({
          message: errorResponse.message,
          open: true,
          severity: SEVERITY.ERROR
        });
      }
    };
  };
  const { useAcknowledgeAlert, useUnacknowledgeAlert, useUpdateAlert } =
    useAlertApi();
  const { mutate: setUnacknowledgedState } = useUnacknowledgeAlert(
    upsertCallback("alerts-center.status_changed", true)
  );
  const { mutate: setAcknowledgedState } = useAcknowledgeAlert(
    upsertCallback("alerts-center.status_changed", true)
  );
  const { mutate: updateAlert } = useUpdateAlert(
    upsertCallback("alerts-center.alert_updated")
  );

  const alertInfo = getAlertInfo({ t, activeAlert });

  const handleChangeStatus = () => {
    if (activeAlert.state === AlertEnums.State.STATE_UNACKNOWLEDGED) {
      setAcknowledgedState({ name: activeAlert.name });
    } else {
      setUnacknowledgedState({ name: activeAlert.name });
    }
  };
  const handleUpdateAlert = name => value => {
    const updatedAlert = structuredClone(activeAlert);
    if (name === "url") {
      updatedAlert.url = value;
    } else {
      updatedAlert[name].value = value;
    }
    updateAlert({
      alert: updatedAlert,
      fieldMask: {
        paths: [name]
      }
    });
  };
  return (
    <AlertDetailsContainer>
      <AlertDetailsHeader
        activeAlert={activeAlert}
        onChangeStatus={handleChangeStatus}
      />
      <Grid container mb={4}>
        <Grid item container xs={12} mb={2}>
          {alertInfo.map(row =>
            row.map(item => (
              <Grid item container xs={6} mb={2} key={item.label}>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>{item.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {item?.overflow && (
                    <Tooltip title={item.value}>
                      <Typography>
                        {item.value !== "-" && item.value.length > 15
                          ? `${item.value.slice(0, 15)}...`
                          : item.value}
                      </Typography>
                    </Tooltip>
                  )}
                  {item?.editable && (
                    <AlertTextField
                      value={item.value}
                      disabled={!activeAlert}
                      name={item.name}
                      onSubmit={handleUpdateAlert(item.name)}
                    />
                  )}
                  {!item?.overflow && !item?.editable && item.value}
                </Grid>
              </Grid>
            ))
          )}
        </Grid>

        <Grid item xs={12}>
          <AlertTextField
            variant={"outlined"}
            value={activeAlert?.description.value}
            disabled={!activeAlert}
            name={"description"}
            onSubmit={handleUpdateAlert("description")}
            minRows={10}
            multiline
          />
        </Grid>
      </Grid>
    </AlertDetailsContainer>
  );
};

export default AlertDetails;
