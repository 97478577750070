import {
  Container,
  Button,
  IconButton,
  inputBaseClasses,
  TextField,
  outlinedInputClasses
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import styled from "../../../theme";

export const AlertDetailsContainer = styled(Container)(() => ({
  minWidth: "420px",
  padding: "24px"
}));
export const AlertSendEmailButton = styled(Button)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.custom.alert.bluishPurple, 0.9),
  paddingLeft: "16px",
  paddingRight: "16px"
}));
export const AlertAcknowledgedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.custom.alert.coldSteel,
  border: `1px solid`,
  borderColor: theme.palette.custom.alert.coldSteel,
  borderRadius: "3px",
  ":disabled": {
    borderColor: lighten(theme.palette.custom.alert.coldSteel, 0.5),
    borderRadius: "3px"
  },
  paddingLeft: "16px",
  paddingRight: "16px"
}));
export const AlertUnacknowledgedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.custom.alert.coldSteel,
  borderColor: theme.palette.custom.alert.coldSteel,
  border: `1px solid`,
  borderRadius: "3px",
  ":disabled": {
    color: theme.palette.common.white,
    backgroundColor: lighten(theme.palette.custom.alert.coldSteel, 0.5)
  },
  paddingLeft: "16px",
  paddingRight: "16px"
}));
export const AlertInput = styled(TextField)(({ theme, multiline }) => {
  if (multiline) {
    return {
      width: "100%",
      [`.${inputBaseClasses.root}`]: {
        "&:hover": {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.custom.alert.bluishPurple
          }
        },
        [`&.${inputBaseClasses.focused}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.custom.alert.bluishPurple
          }
        },
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: lighten(theme.palette.custom.alert.bluishPurple, 0.5)
        }
      }
    };
  }
  return {
    [`.${inputBaseClasses.root}`]: {
      paddingRight: "8px",
      ":before": {
        borderColor: "transparent"
      },
      ":after": {
        borderBottom: `2px solid ${theme.palette.custom.alert.bluishPurple}`
      },
      [`:hover:not(.${inputBaseClasses.disabled}):before`]: {
        borderColor: theme.palette.custom.alert.bluishPurple
      }
    },
    [`.${inputBaseClasses.input}`]: {
      color: theme.palette.custom.alert.bluishPurple,
      [`&.${inputBaseClasses.disabled}`]: {
        color: theme.palette.custom.alert.bluishPurple,
        "-webkit-text-fill-color": theme.palette.custom.alert.bluishPurple
      }
    }
  };
});
export const AlertIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "3px",
  border: "1px solid",
  borderColor: theme.palette.custom.alert.coldSteel
}));
export const AlertUpdateIconButton = styled(AlertIconButton)(({ theme }) => ({
  backgroundColor: theme.palette.custom.alert.skyBlue,
  ":hover": {
    backgroundColor: lighten(theme.palette.custom.alert.skyBlue, 0.5)
  }
}));
export const AlertCancelIconButton = styled(AlertIconButton)(({ theme }) => ({
  backgroundColor: theme.palette.custom.alert.guillimanBlue,
  ":hover": {
    backgroundColor: lighten(theme.palette.custom.alert.guillimanBlue, 0.5)
  }
}));
