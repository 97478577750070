import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { prism } from "@tsg/1st-grpc-web";
import IAlert = prism.v1.core.IAlert;

import AlertNotificationItem from "./alert-notification-item";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import {
  AlertNotificationsButton,
  AlertNotificationsCard,
  AlertNotificationsCardContent,
  AlertNotificationsCardHeader,
  AlertNotificationsDivider,
  AlertNotificationsDrawer
} from "./alert-notifications-components";
import { useTranslation } from "react-i18next";

interface OwnProps {
  open: boolean;
  openAlertCenter: () => void;
  onClose: () => void;
  alertsPreview: IAlert[];
  alertsContainerRef: React.RefObject<HTMLDivElement>;
  isFetchAlerts: boolean;
}

const AlertNotifications = ({
  open,
  onClose,
  alertsPreview,
  openAlertCenter,
  alertsContainerRef,
  isFetchAlerts
}: OwnProps) => {
  const { t } = useTranslation();
  const handleAlertClick = () => {
    openAlertCenter();
    onClose();
  };

  return (
    <AlertNotificationsDrawer anchor="right" open={open} onClose={onClose}>
      <AlertNotificationsCard>
        <AlertNotificationsCardHeader
          title={t("alerts.title")}
          action={
            <AlertNotificationsButton
              onClick={handleAlertClick}
              variant="contained"
              endIcon={<ChevronRightIcon />}
              startIcon={<NotificationsIcon />}
            >
              {t("alerts.button")}
            </AlertNotificationsButton>
          }
        />
        <AlertNotificationsCardContent ref={alertsContainerRef}>
          {alertsPreview?.map((alert, index) => (
            <React.Fragment key={index}>
              <AlertNotificationItem
                key={index}
                subjectType={alert.subjectType}
                parent={alert.parent}
                date={Number(alert.auditInfo.createTime.seconds) * 1000}
                subject={alert.subject}
                description={alert.description?.value}
                name={alert.name}
                actions={alert.actions}
                callback={handleAlertClick}
              />
              <AlertNotificationsDivider />
            </React.Fragment>
          ))}
          {isFetchAlerts && (
            <Box textAlign={"center"}>
              <CircularProgress />
            </Box>
          )}
        </AlertNotificationsCardContent>
      </AlertNotificationsCard>
    </AlertNotificationsDrawer>
  );
};

export default AlertNotifications;
