import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import { DATA_COMPETITION_KEY } from "common/QueryKeys";
import { buttonStyle } from "components/style/app-style";
import { CompetitorCommands } from "common/utils/enum-utils";

interface Props {
  command: CompetitorCommands;
  row?: prism.v1.data.ICompetition;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
}
const MarketActionViewWrapper = styled(Box)({
  padding: "16px 25px"
});
const MarketActionViewBody = styled(Box)({
  padding: "16px 0px"
});
const MarketActionViewFooter = styled(Box)({
  display: "flex",
  justifyContent: "center"
});

function CompetitorActionView({
  row,
  handleSuccess,
  handleError,
  command
}: Props) {
  const { t } = useTranslation();
  const {
    useScratchCompetitionCompetitor,
    useParticipateCompetitionCompetitor,
    useInvalidateCompetitionCompetitor,
    useEnterCompetitionCompetitor,
    useEligibleCompetitionCompetitor,
    useDisqualifyCompetitionCompetitor
  } = useDataApi();
  const queryClient = useQueryClient();

  const requestCallback = () => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [DATA_COMPETITION_KEY] });
        handleSuccess &&
          handleSuccess(
            t(`competitor_command_success.${command}`, {
              name: row?.displayName.value
            })
          );
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };
  const { mutate: mutateScratchRunner, isPending: isLoadingScratchRunner } =
    useScratchCompetitionCompetitor(requestCallback());
  const {
    mutate: mutateParticipateCompetitor,
    isPending: isLoadingParticipateRunner
  } = useParticipateCompetitionCompetitor(requestCallback());
  const {
    mutate: mutateInvalidateCompetitor,
    isPending: isLoadingInvalidateRunner
  } = useInvalidateCompetitionCompetitor(requestCallback());
  const { mutate: mutateEnterCompetitor, isPending: isLoadingEnterRunner } =
    useEnterCompetitionCompetitor(requestCallback());
  const {
    mutate: mutateEligibleCompetitor,
    isPending: isLoadingEligibleRunner
  } = useEligibleCompetitionCompetitor(requestCallback());
  const {
    mutate: mutateDisqualifyCompetitor,
    isPending: isLoadingDisqualityRunner
  } = useDisqualifyCompetitionCompetitor(requestCallback());

  const isLoading = [
    isLoadingScratchRunner,
    isLoadingParticipateRunner,
    isLoadingInvalidateRunner,
    isLoadingEnterRunner,
    isLoadingEligibleRunner,
    isLoadingDisqualityRunner
  ].some(Boolean);

  const handlers = {
    [CompetitorCommands.SCRATCH_RUNNER]: mutateScratchRunner,
    [CompetitorCommands.PARTICIPATE_RUNNER]: mutateParticipateCompetitor,
    [CompetitorCommands.INVALIDATE_RUNNER]: mutateInvalidateCompetitor,
    [CompetitorCommands.ENTER_RUNNER]: mutateEnterCompetitor,
    [CompetitorCommands.ELIGIBLE_RUNNER]: mutateEligibleCompetitor,
    [CompetitorCommands.DISQUALIFY_RUNNER]: mutateDisqualifyCompetitor
  };

  const handleSubmit = () => {
    handlers[command] &&
      handlers[command]({
        name: row.competitionName,
        shortName: row.shortName
      });
  };
  return (
    <MarketActionViewWrapper>
      <Box>
        <Typography variant="h6">
          {t(`competitors_actions.${command}`)}
        </Typography>
      </Box>
      <MarketActionViewBody>
        <Typography variant="h6">{row?.displayName.value}</Typography>
      </MarketActionViewBody>
      <MarketActionViewFooter>
        <LoadingButton
          variant="contained"
          fullWidth
          disableElevation
          sx={row ? buttonStyle() : null}
          type="submit"
          size="large"
          loading={isLoading}
          loadingPosition="start"
          onClick={handleSubmit}
        >
          {t("market_actions.confirm_button")}
        </LoadingButton>
      </MarketActionViewFooter>
    </MarketActionViewWrapper>
  );
}

export default CompetitorActionView;
