import React, { useEffect, useState } from "react";
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { useAuth } from "react-oidc-context";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import routes from "common/routes";
import Loader from "components/common/Loader";
import DashboardPage from "pages/common/Dashboard";
import VideoEncoderListPage from "pages/video/VideoEncoder";
import AppHeader from "components/common/Layout/app-header";
import LogRocket from "components/common/LogRocket";
import BookingListPage from "pages/video/VideoBooking";
import AllowedCountryListPage from "pages/video/ContentAuthorizationRule";
import VideoSourceListPage from "pages/video/VideoSource";
import VideoDecoderListPage from "pages/video/VideoDecoder";
import VideoChannelListPage from "pages/video/VideoChannel";
import OrganizationListPage from "pages/core/Organization";
import OrganizationalUnitListPage from "pages/core/OrganizationalUnit";
import { OrganizationDetailListPage } from "pages/core/OrganizationDetail/list";
import MarketWinSummaryPage from "../pages/transaction/market-win-summary";
import { appStyle } from "./style";
import MultiviewPlayerList from "pages/video/MultiviewPlayer/list";
import RoleListPage from "pages/iam/Role";
import JockeyPage from "pages/data/Jockey";
import MarketListPage from "pages/data/Market";
import MarketPlusPage from "pages/data/Market+";
import CompetitionPlusPage from "pages/data/Competition+";
import CompetitionPage from "pages/data/Competition";
import ParimutuelEventPage from "pages/data/ParimutuelEvent";
import AccountPage from "pages/common/Account";
import UserListPage from "pages/iam/User";
import CountriesListPage from "pages/core/Country";
import BetsPage from "pages/transaction/Bets";
import MarketPricesPage from "pages/transaction/MarketPrices";
import MarketPoolsPage from "pages/transaction/market-pools";
import MarketPriceConfigurationPage from "pages/transaction/MarketPriceConfiguration";
import PriceConfigurationPage from "pages/transaction/PriceConfiguration";
import WageringSourcesPage from "pages/transaction/WageringSources";
import VideoConsumerListPage from "pages/video/VideoConsumer";
import ParimutuelProgramPage from "pages/data/ParimutuelProgram";
import VideoReplayPage from "pages/video/VideoReplay";
import UserGroupListPage from "pages/iam/UserGroup";
import RoleBindingsListPage from "pages/iam/RoleBinding";
import AllConnectionsPage from "pages/itsp";
import { SnackbarContextProvider } from "components/snackbar-context/snackbar-context";
import VideoStreamUsageListPage from "pages/video/VideoStreamUsage";
import VideoStreamPricingListPage from "pages/video/VideoStreamPricingConfiguration";
import AppSidebar from "./common/Layout/app-sidebar";
import MarketNetworkPoolsPage from "pages/transaction/market-network-pools";
import AlertsPage from "pages/common/Alerts";
import VideoStreamInvoiceListPage from "pages/video/VideoStreamInvoice";
import VideoStreamInvoiceHtmlListPage from "pages/video/VideoStreamInvoiceHtml";
import ConsumerRulesListPage from "pages/video/ConsumerRules";
import VideoStreamUserUsageListPage from "pages/video/VideoStreamUserUsage";
import VideoStreamHighUsageListPage from "pages/video/VideoStreamHighUsage";
import AuthorizedClientListPage from "pages/iam/AuthorizedClient";
import { usePrismWebContext } from "./common/context";
import UserSourceListPage from "pages/iam/UserSource";
import MarketOddsPage from "pages/transaction/MarketOdds";
import MarketWillPaysPage from "pages/transaction/MarketWillPays";
import SystemIntegrityPage from "pages/transaction/SystemIntegrity";
import MarketTypeListPage from "pages/data/MarketType/list";
import MarketCollationPage from "pages/transaction/MarketCollation";
import BetValuatorPage from "pages/transaction/BetValuator";

const checkProfilePage = path => {
  const profilePageRoutes = [routes.account.path];

  return profilePageRoutes.some(route =>
    matchPath(path, {
      path: route,
      exact: true,
      strict: true
    })
  );
};

const App = () => {
  const { appSidebarState } = usePrismWebContext();
  const [isAppSidebarOpen, setIsAppSidebarOpen] = appSidebarState;
  const { isLoading, user, signinRedirect } = useAuth();
  const location = useLocation();
  const [isProfilePage, setIsProfilePage] = useState(
    checkProfilePage(location.pathname)
  );

  const history = useHistory();

  const isAuthenticated = !!user;

  useEffect(() => {
    const fn = async () => {
      history.push("/login");
      // await signinRedirect();
    };

    if (!isAuthenticated && !isLoading) {
      void fn();
    }
  }, [isLoading, isAuthenticated, signinRedirect]);

  useEffect(() => {
    setIsProfilePage(checkProfilePage(location.pathname));
  }, [location.pathname]);

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      <CssBaseline />
      <LogRocket />
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap"
        }}
      >
        <AppSidebar />
        <Box
          component="main"
          sx={{
            position: "relative",
            p: 3,
            display: "block",
            flexDirection: "column",
            overflow: "hidden",
            width: "100%"
          }}
        >
          <AppHeader
            isSidebarOpen={isAppSidebarOpen}
            isProfilePage={isProfilePage}
          />

          <Box sx={appStyle}>
            <Switch>
              <Route path={routes.account.path} component={AccountPage} />
              <Route
                path={routes.organization.path}
                component={OrganizationListPage}
              />
              <Route
                path={routes.organizationalUnit.path}
                component={OrganizationalUnitListPage}
              />
              <Route
                path={routes.organizationDetail.path}
                component={OrganizationDetailListPage}
              />
              <Route path={routes.roles.path} component={RoleListPage} />
              <Route path={routes.user.path} component={UserListPage} />
              <Route
                path={routes.videoEncoder.path}
                component={VideoEncoderListPage}
              />
              <Route
                path={routes.videoDecoder.path}
                component={VideoDecoderListPage}
              />
              <Route
                path={routes.videoSource.path}
                component={VideoSourceListPage}
              />
              <Route
                path={routes.videoChannel.path}
                component={VideoChannelListPage}
              />
              <Route path={routes.bookings.path} component={BookingListPage} />
              <Route
                path={routes.allowedcountries.path}
                component={AllowedCountryListPage}
              />
              <Route
                path={routes.multiviewPlayer.path}
                component={MultiviewPlayerList}
              />
              <Route
                path={routes.countries.path}
                component={CountriesListPage}
              />
              <Route
                path={routes.videoConsumer.path}
                component={VideoConsumerListPage}
              />
              <Route
                path={routes.videoReplay.path}
                component={VideoReplayPage}
              />
              <Route
                path={routes.userGroup.path}
                component={UserGroupListPage}
              />
              <Route path={routes.jockey.path} component={JockeyPage} />
              <Route path={routes.market.path} component={MarketListPage} />
              <Route path={routes.marketplus.path} component={MarketPlusPage} />
              <Route
                path={routes.marketType.path}
                component={MarketTypeListPage}
              />
              <Route
                path={routes.parimutuelProgram.path}
                component={ParimutuelProgramPage}
              />
              <Route
                path={routes.competition.path}
                component={CompetitionPage}
              />
              <Route
                path={routes.competitionplus.path}
                component={CompetitionPlusPage}
              />
              <Route
                path={routes.parimutuelEvent.path}
                component={ParimutuelEventPage}
              />

              <Route
                path={routes.wageringSources.path}
                component={WageringSourcesPage}
              />
              <Route
                path={routes.priceConfiguration.path}
                component={PriceConfigurationPage}
              />
              <Route
                path={routes.roleBinding.path}
                component={RoleBindingsListPage}
              />
              <Route path={routes.bets.path} component={BetsPage} />
              <Route
                path={routes.marketPrice.path}
                component={MarketPricesPage}
              />
              <Route path={routes.marketOdds.path} component={MarketOddsPage} />
              <Route
                path={routes.marketWillPays.path}
                component={MarketWillPaysPage}
              />
              <Route
                path={routes.systemIntegrity.path}
                component={SystemIntegrityPage}
              />
              <Route
                path={routes.marketCollation.path}
                component={MarketCollationPage}
              />
              <Route
                path={routes.marketTotals.path}
                component={MarketPoolsPage}
              />
              <Route
                path={routes.betValuator.path}
                component={BetValuatorPage}
              />
              <Route
                path={routes.marketNetworkPools.path}
                component={MarketNetworkPoolsPage}
              />
              <Route
                path={routes.marketPriceConfiguration.path}
                component={MarketPriceConfigurationPage}
              />
              <Route
                path={routes.marketWinSummary.path}
                component={MarketWinSummaryPage}
              />
              <Route
                path={routes.videoStreamPriceConfiguration.path}
                component={VideoStreamPricingListPage}
              />
              <Route
                path={routes.videoStreamUsage.path}
                component={VideoStreamUsageListPage}
              />
              <Route
                path={routes.userReport.path}
                component={VideoStreamUserUsageListPage}
              />
              <Route
                path={routes.highUsage.path}
                component={VideoStreamHighUsageListPage}
              />
              <Route
                path={routes.videoStreamInvoice.path}
                component={VideoStreamInvoiceListPage}
              />
              <Route
                path={routes.videoStreamInvoiceHtml.path}
                component={VideoStreamInvoiceHtmlListPage}
              />
              <Route
                path={routes.consumerRules.path}
                component={ConsumerRulesListPage}
              />
              <Route
                path={routes.authorizedClients.path}
                component={AuthorizedClientListPage}
              />
              <Route
                path={routes.userSource.path}
                component={UserSourceListPage}
              />
              <SnackbarContextProvider>
                <Route
                  path={routes.itspConnections.path}
                  component={AllConnectionsPage}
                />
              </SnackbarContextProvider>

              <Route path={routes.dashboard.path} component={DashboardPage} />
              <Route path={routes.alerts.path} component={AlertsPage} />
            </Switch>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default App;
