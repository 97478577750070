import React, { useContext, useMemo } from "react";
import { grpc, prism } from "@tsg/1st-grpc-web";
import { useAuth } from "react-oidc-context";
import { interceptErrors } from "./errors";

export const ApiClientContext = React.createContext<ApiClientContextInterface>(
  {} as any
);

export interface ApiClientContextInterface {
  orgClient: prism.v1.core.OrganizationServiceSimpleClient;
  orgUnitClient: prism.v1.core.OrganizationalUnitServiceSimpleClient;
  orgUnitTypeClient: prism.v1.core.OrganizationalUnitTypeServiceSimpleClient;
  sourceClient: prism.v1.core.SourceServiceSimpleClient;
  sourceTypeClient: prism.v1.core.SourceTypeServiceSimpleClient;
  videoSourceClient: prism.v1.video.VideoSourceServiceSimpleClient;
  cameraAngleClient: prism.v1.video.CameraAngleServiceSimpleClient;
  foreignIdClient: prism.v1.core.ForeignIdentifierTypeServiceSimpleClient;
  videoEncoderClient: prism.v1.video.VideoEncoderServiceSimpleClient;
  videoDecoderClient: prism.v1.video.VideoDecoderServiceSimpleClient;
  videoChannelClient: prism.v1.video.VideoChannelServiceSimpleClient;
  videoBookingClient: prism.v1.video.VideoBookingServiceSimpleClient;
  videoConsumerClient: prism.v1.video.VideoConsumerServiceSimpleClient;
  videoReplayClient: prism.v1.video.VideoReplayServiceSimpleClient;
  allowedCountryClient: prism.v1.video.ContentAuthorizationRuleServiceSimpleClient;
  countryClient: prism.v1.core.CountryServiceSimpleClient;
  iamRolesClient: prism.v1.iam.RoleServiceSimpleClient;
  iamPermissionsClient: prism.v1.iam.PermissionServiceSimpleClient;
  iamUserServiceClient: prism.v1.iam.UserServiceSimpleClient;
  iamUserSourceServiceClient: prism.v1.iam.UserSourceServiceSimpleClient;
  iamUserGroupServiceClient: prism.v1.iam.UserGroupServiceSimpleClient;
  iamRoleBindingServiceClient: prism.v1.iam.RoleBindingServiceSimpleClient;
  iamAuthorizedClientServiceClient: prism.v1.iam.AuthorizedClientServiceSimpleClient;
  marketOddsClient: prism.v1.transaction.MarketOddsServiceSimpleClient;
  marketWillPaysClient: prism.v1.transaction.MarketWillPaysServiceSimpleClient;
  marketPriceClient: prism.v1.transaction.MarketPriceServiceSimpleClient;
  marketCollationClient: prism.v1.transaction.MarketCollationServiceSimpleClient;
  marketPriceConfigurationClient: prism.v1.transaction.MarketPriceConfigurationServiceSimpleClient;
  marketWinSummaryClient: prism.v1.transaction.MarketWinSummaryServiceSimpleClient;
  marketSourceClient: prism.v1.data.MarketServiceSimpleClient;
  marketSourceSourceClient: prism.v1.data.MarketSourceServiceSimpleClient;
  marketTypeSourceClient: prism.v1.data.MarketTypeServiceSimpleClient;
  paramutuelEventClient: prism.v1.data.ParimutuelEventServiceSimpleClient;
  parimutuelprogramSourceClient: prism.v1.data.ParimutuelProgramServiceSimpleClient;
  competitionSourceClient: prism.v1.data.CompetitionServiceSimpleClient;
  jockeySourceClient: prism.v1.data.JockeyServiceSimpleClient;
  fixtureSourceClient: prism.v1.data.FixtureSourceServiceSimpleClient;
  facilitySourceClient: prism.v1.data.FacilityServiceSimpleClient;
  leagueSourceClient: prism.v1.data.LeagueServiceSimpleClient;
  sportSourceClient: prism.v1.data.SportServiceSimpleClient;
  priceConfigurationClient: prism.v1.transaction.PriceConfigurationServiceSimpleClient;
  wageringSourceClient: prism.v1.transaction.WageringSourceServiceSimpleClient;
  betGetServiceClient: prism.v1.transaction.BetGetServiceSimpleClient;
  betValuatorClient: prism.v1.transaction.BetValuatorServiceSimpleClient;
  marketClient: prism.v1.data.MarketServiceSimpleClient;
  marketNetworkPoolClient: prism.v1.transaction.MarketNetworkPoolServiceSimpleClient;
  marketTotalsClient: prism.v1.transaction.MarketTotalServiceSimpleClient;
  marketWageringSourcePoolClient: prism.v1.transaction.MarketWageringSourcePoolServiceSimpleClient;
  itspConnectionClient: prism.v1.itsp.ItspConnectionServiceSimpleClient;
  parimutuelClient: prism.v1.data.ParimutuelProgramServiceSimpleClient;
  videoStreamPricingConfigClient: prism.v1.video.VideoStreamPricingConfigurationServiceSimpleClient;
  videoStreamUsageClient: prism.v1.video.VideoStreamUsageServiceSimpleClient;
  alertClient: prism.v1.core.AlertServiceSimpleClient;
  VideoStreamInvoiceClient: prism.v1.video.VideoStreamInvoiceServiceSimpleClient;
  VideoChannelClusterClient: prism.v1.video.VideoChannelClusterServiceSimpleClient;
}

export const useApiClient: () => ApiClientContextInterface = () =>
  useContext(ApiClientContext);

export const ApiClientProvider = ({
  children,
  enableGrpcWebDevTools = Boolean(window.__GRPCWEB_DEVTOOLS__)
}) => {
  const { user } = useAuth();

  const value = useMemo(() => {
    const clientOptions: grpc.CreateClientOptions = {
      auth: async () => user?.access_token
    };

    const orgClient = grpc.createClient(
      prism.v1.core.OrganizationServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const orgUnitClient = grpc.createClient(
      prism.v1.core.OrganizationalUnitServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const orgUnitTypeClient = grpc.createClient(
      prism.v1.core.OrganizationalUnitTypeServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const sourceClient = grpc.createClient(
      prism.v1.core.SourceServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const sourceTypeClient = grpc.createClient(
      prism.v1.core.SourceTypeServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const videoSourceClient = grpc.createClient(
      prism.v1.video.VideoSourceServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const videoConsumerClient = grpc.createClient(
      prism.v1.video.VideoConsumerServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const cameraAngleClient = grpc.createClient(
      prism.v1.video.CameraAngleServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const foreignIdClient = grpc.createClient(
      prism.v1.core.ForeignIdentifierTypeServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const videoEncoderClient = grpc.createClient(
      prism.v1.video.VideoEncoderServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const videoDecoderClient = grpc.createClient(
      prism.v1.video.VideoDecoderServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const videoChannelClient = grpc.createClient(
      prism.v1.video.VideoChannelServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const videoBookingClient = grpc.createClient(
      prism.v1.video.VideoBookingServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const allowedCountryClient = grpc.createClient(
      prism.v1.video.ContentAuthorizationRuleServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const countryClient = grpc.createClient(
      prism.v1.core.CountryServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const iamRolesClient = grpc.createClient(
      prism.v1.iam.RoleServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const iamPermissionsClient = grpc.createClient(
      prism.v1.iam.PermissionServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const iamUserServiceClient = grpc.createClient(
      prism.v1.iam.UserServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const iamUserSourceServiceClient = grpc.createClient(
      prism.v1.iam.UserSourceServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const iamRoleBindingServiceClient = grpc.createClient(
      prism.v1.iam.RoleBindingServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const iamAuthorizedClientServiceClient = grpc.createClient(
      prism.v1.iam.AuthorizedClientServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const marketSourceClient = grpc.createClient(
      prism.v1.data.MarketServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const marketTypeSourceClient = grpc.createClient(
      prism.v1.data.MarketTypeServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const parimutuelprogramSourceClient = grpc.createClient(
      prism.v1.data.ParimutuelProgramServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const paramutuelEventClient = grpc.createClient(
      prism.v1.data.ParimutuelEventServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const competitionSourceClient = grpc.createClient(
      prism.v1.data.CompetitionServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const jockeySourceClient = grpc.createClient(
      prism.v1.data.JockeyServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const fixtureSourceClient = grpc.createClient(
      prism.v1.data.FixtureSourceServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const facilitySourceClient = grpc.createClient(
      prism.v1.data.FacilityServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const leagueSourceClient = grpc.createClient(
      prism.v1.data.LeagueServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const sportSourceClient = grpc.createClient(
      prism.v1.data.SportServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );
    const videoReplayClient = grpc.createClient(
      prism.v1.video.VideoReplayServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const iamUserGroupServiceClient = grpc.createClient(
      prism.v1.iam.UserGroupServiceSimpleClient,
      prism.v1.iam.packageName,
      clientOptions
    );

    const priceConfigurationClient = grpc.createClient(
      prism.v1.transaction.PriceConfigurationServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const wageringSourceClient = grpc.createClient(
      prism.v1.transaction.WageringSourceServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const betGetServiceClient = grpc.createClient(
      prism.v1.transaction.BetGetServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const betValuatorClient = grpc.createClient(
      prism.v1.transaction.BetValuatorServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const marketTotalsClient = grpc.createClient(
      prism.v1.transaction.MarketTotalServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const marketWageringSourcePoolClient = grpc.createClient(
      prism.v1.transaction.MarketWageringSourcePoolServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const marketOddsClient = grpc.createClient(
      prism.v1.transaction.MarketOddsServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const marketWillPaysClient = grpc.createClient(
      prism.v1.transaction.MarketWillPaysServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const marketCollationClient = grpc.createClient(
      prism.v1.transaction.MarketCollationServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const marketNetworkPoolClient = grpc.createClient(
      prism.v1.transaction.MarketNetworkPoolServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );
    const marketWinSummaryClient = grpc.createClient(
      prism.v1.transaction.MarketWinSummaryServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const itspConnectionClient = grpc.createClient(
      prism.v1.itsp.ItspConnectionServiceSimpleClient,
      prism.v1.itsp.packageName,
      clientOptions
    );

    const parimutuelClient = grpc.createClient(
      prism.v1.data.ParimutuelProgramServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const marketPriceConfigurationClient = grpc.createClient(
      prism.v1.transaction.MarketPriceConfigurationServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const videoStreamUsageClient = grpc.createClient(
      prism.v1.video.VideoStreamUsageServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const marketSourceSourceClient = grpc.createClient(
      prism.v1.data.MarketSourceServiceSimpleClient,
      prism.v1.data.packageName,
      clientOptions
    );

    const marketPriceClient = grpc.createClient(
      prism.v1.transaction.MarketPriceServiceSimpleClient,
      prism.v1.transaction.packageName,
      clientOptions
    );

    const videoStreamPricingConfigClient = grpc.createClient(
      prism.v1.video.VideoStreamPricingConfigurationServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const alertClient = grpc.createClient(
      prism.v1.core.AlertServiceSimpleClient,
      prism.v1.core.packageName,
      clientOptions
    );

    const VideoStreamInvoiceClient = grpc.createClient(
      prism.v1.video.VideoStreamInvoiceServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    const VideoChannelClusterClient = grpc.createClient(
      prism.v1.video.VideoChannelClusterServiceSimpleClient,
      prism.v1.video.packageName,
      clientOptions
    );

    interceptErrors(orgClient);
    interceptErrors(orgUnitClient);
    interceptErrors(orgUnitTypeClient);
    interceptErrors(sourceClient);
    interceptErrors(sourceTypeClient);
    interceptErrors(videoSourceClient);
    interceptErrors(cameraAngleClient);
    interceptErrors(foreignIdClient);
    interceptErrors(videoEncoderClient);
    interceptErrors(videoDecoderClient);
    interceptErrors(videoConsumerClient);
    interceptErrors(allowedCountryClient);
    interceptErrors(countryClient);
    interceptErrors(videoChannelClient);
    interceptErrors(videoBookingClient);
    interceptErrors(iamRolesClient);
    interceptErrors(iamPermissionsClient);
    interceptErrors(iamUserServiceClient);
    interceptErrors(iamUserSourceServiceClient);
    interceptErrors(marketOddsClient);
    interceptErrors(marketWillPaysClient);
    interceptErrors(marketCollationClient);
    interceptErrors(marketPriceClient);
    interceptErrors(marketPriceConfigurationClient);
    interceptErrors(marketSourceClient);
    interceptErrors(marketSourceSourceClient);
    interceptErrors(marketTypeSourceClient);
    interceptErrors(paramutuelEventClient);
    interceptErrors(parimutuelprogramSourceClient);
    interceptErrors(competitionSourceClient);
    interceptErrors(jockeySourceClient);
    interceptErrors(fixtureSourceClient);
    interceptErrors(facilitySourceClient);
    interceptErrors(leagueSourceClient);
    interceptErrors(sportSourceClient);
    interceptErrors(iamRoleBindingServiceClient);
    interceptErrors(iamAuthorizedClientServiceClient);
    interceptErrors(videoReplayClient);
    interceptErrors(iamUserGroupServiceClient);
    interceptErrors(priceConfigurationClient);
    interceptErrors(wageringSourceClient);
    interceptErrors(betGetServiceClient);
    interceptErrors(betValuatorClient);
    interceptErrors(itspConnectionClient);
    interceptErrors(parimutuelClient);
    interceptErrors(videoStreamPricingConfigClient);
    interceptErrors(videoStreamUsageClient);
    interceptErrors(marketNetworkPoolClient);
    interceptErrors(marketWinSummaryClient);
    interceptErrors(marketTotalsClient);
    interceptErrors(marketWageringSourcePoolClient);
    interceptErrors(alertClient);
    interceptErrors(VideoStreamInvoiceClient);
    interceptErrors(VideoChannelClusterClient);

    if (enableGrpcWebDevTools) {
      window.__GRPCWEB_DEVTOOLS__([
        orgClient,
        orgUnitClient,
        orgUnitTypeClient,
        sourceClient,
        sourceTypeClient,
        videoSourceClient,
        cameraAngleClient,
        foreignIdClient,
        videoEncoderClient,
        videoDecoderClient,
        videoConsumerClient,
        allowedCountryClient,
        countryClient,
        videoBookingClient,
        iamRolesClient,
        iamPermissionsClient,
        videoChannelClient,
        videoBookingClient,
        iamUserServiceClient,
        iamUserSourceServiceClient,
        marketTotalsClient,
        marketOddsClient,
        marketWillPaysClient,
        marketCollationClient,
        marketPriceClient,
        marketNetworkPoolClient,
        marketWinSummaryClient,
        marketPriceConfigurationClient,
        marketSourceClient,
        marketSourceSourceClient,
        marketTypeSourceClient,
        marketWageringSourcePoolClient,
        parimutuelClient,
        paramutuelEventClient,
        parimutuelprogramSourceClient,
        competitionSourceClient,
        jockeySourceClient,
        fixtureSourceClient,
        facilitySourceClient,
        leagueSourceClient,
        sportSourceClient,
        videoReplayClient,
        iamUserGroupServiceClient,
        priceConfigurationClient,
        wageringSourceClient,
        betGetServiceClient,
        iamRoleBindingServiceClient,
        iamAuthorizedClientServiceClient,
        itspConnectionClient,
        videoStreamPricingConfigClient,
        videoStreamUsageClient,
        alertClient,
        VideoStreamInvoiceClient,
        VideoChannelClusterClient
      ]);
    }

    return {
      orgClient,
      orgUnitClient,
      orgUnitTypeClient,
      sourceClient,
      sourceTypeClient,
      videoSourceClient,
      foreignIdClient,
      cameraAngleClient,
      videoEncoderClient,
      videoDecoderClient,
      videoConsumerClient,
      videoChannelClient,
      allowedCountryClient,
      countryClient,
      videoBookingClient,
      iamRolesClient,
      iamPermissionsClient,
      iamUserServiceClient,
      iamUserSourceServiceClient,
      marketTotalsClient,
      marketOddsClient,
      marketWillPaysClient,
      marketCollationClient,
      marketPriceClient,
      marketNetworkPoolClient,
      marketWinSummaryClient,
      marketPriceConfigurationClient,
      marketSourceClient,
      marketSourceSourceClient,
      marketTypeSourceClient,
      marketWageringSourcePoolClient,
      paramutuelEventClient,
      parimutuelprogramSourceClient,
      competitionSourceClient,
      jockeySourceClient,
      fixtureSourceClient,
      facilitySourceClient,
      leagueSourceClient,
      sportSourceClient,
      videoReplayClient,
      iamUserGroupServiceClient,
      priceConfigurationClient,
      wageringSourceClient,
      betGetServiceClient,
      betValuatorClient,
      iamRoleBindingServiceClient,
      iamAuthorizedClientServiceClient,
      itspConnectionClient,
      parimutuelClient,
      videoStreamPricingConfigClient,
      videoStreamUsageClient,
      alertClient,
      VideoStreamInvoiceClient,
      VideoChannelClusterClient
    };
  }, [user, enableGrpcWebDevTools]);

  return (
    <ApiClientContext.Provider value={value}>
      {children}
    </ApiClientContext.Provider>
  );
};
