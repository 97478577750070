import { useMemo } from "react";
import { useApiClient } from "../ApiClientContext";
import { makeHooksForApiClient } from "../auto-api-hook-wrapper";

export const useTransactionApi = () => {
  const {
    betGetServiceClient,
    marketPriceClient,
    marketPriceConfigurationClient,
    priceConfigurationClient,
    wageringSourceClient,
    marketNetworkPoolClient,
    marketOddsClient,
    marketWillPaysClient,
    marketCollationClient,
    marketWinSummaryClient,
    marketTotalsClient,
    betValuatorClient,
    marketWageringSourcePoolClient
  } = useApiClient();

  return useMemo(
    () => ({
      ...makeHooksForApiClient(betGetServiceClient),
      ...makeHooksForApiClient(betValuatorClient),
      ...makeHooksForApiClient(marketPriceClient),
      ...makeHooksForApiClient(marketPriceConfigurationClient),
      ...makeHooksForApiClient(priceConfigurationClient),
      ...makeHooksForApiClient(wageringSourceClient),
      ...makeHooksForApiClient(marketNetworkPoolClient),
      ...makeHooksForApiClient(marketOddsClient),
      ...makeHooksForApiClient(marketWillPaysClient),
      ...makeHooksForApiClient(marketCollationClient),
      ...makeHooksForApiClient(marketWinSummaryClient),
      ...makeHooksForApiClient(marketTotalsClient),
      ...makeHooksForApiClient(marketWageringSourcePoolClient)
    }),
    [
      betGetServiceClient,
      betValuatorClient,
      marketPriceClient,
      marketPriceConfigurationClient,
      priceConfigurationClient,
      wageringSourceClient,
      marketNetworkPoolClient,
      marketOddsClient,
      marketWillPaysClient,
      marketCollationClient,
      marketWinSummaryClient,
      marketTotalsClient,
      marketWageringSourcePoolClient
    ]
  );
};
